





















































































import { Component, Vue } from "vue-property-decorator";
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';

import PlatformChart from "./202208_platform_chart.vue";
import USDChart from "./202208_USD_chart.vue";
// import StrategyChart from "./202205_strategy_chart.vue";

Vue.component('PlatformChart', PlatformChart);
Vue.component('USDChart', USDChart);
// Vue.component('StrategyChart', StrategyChart);

@Component
export default class Plan extends Vue {
  public headers = [
    { text: '策略名稱', sortable: false, value: 'name', align: 'left'},
    { text: '配置比例', sortable: false, value: 'proportion', align: 'left'},
    { text: '使用平台', sortable: false, value: 'platform', align: 'left'},
    { text: '使用穩定幣', sortable: false, value: 'token', align: 'left'},
    // { text: '策略風險', sortable: false, value: 'strat_risk', align: 'left'},
    // { text: '系統性風險', sortable: false, value: 'system_risk', align: 'left'},
    // { text: '預估年化收益', sortable: false, value: 'apr', align: 'left'},
  ];

  public strategies = [
    // { name: '(Tulip) leveraged yield farming', proportion: '5.49%', platform: 'Solana', token: 'USDC', strat_risk: '高', system_risk: '低', apr: '29%'},
    // { name: '(Francium) leveraged yield farming', proportion: '27.21%', platform: 'Solana', token: 'USDT', strat_risk: '中', system_risk: '低', apr: '18%'},
    // { name: '(Katana) option vault', proportion: '6.03%', platform: 'Solana', token: 'USDC', strat_risk: '高', system_risk: '低', apr: '5%'},
    // { name: '(Solend) interest arbitrage', proportion: '18.65%', platform: 'Solana', token: 'USDC', strat_risk: '低', system_risk: '低', apr: '10%'},
    // { name: '(Solend + Francium) interest arbitrage', proportion: '5.38%', platform: 'Solana', token: 'USDC', strat_risk: '低', system_risk: '低', apr: '8%'},    
    // { name: '(Alpaca) leveraged yield farming', proportion: '35.00%', platform: 'BSC', token: 'USDT', strat_risk: '中', system_risk: '低', apr: '14%'},
    // { name: '(FTX Blockfolio) hot wallet', proportion: '0.84%', platform: 'FTX', token: 'USDT + USDC', strat_risk: '低', system_risk: '低', apr: '5%'},
    // { name: '(Single Finance) lending', proportion: '13.34%', platform: 'Cronos', token: 'USDT', strat_risk: '低', system_risk: '低', apr: '8%'},
    // { name: '(Justlend) lending', proportion: '2.75%', platform: 'Tron', token: 'USDD + USDC', strat_risk: '低', system_risk: '中', apr: '53%'},
    // { name: '(Mercurial) stablecoin farming', proportion: '3.22%', platform: 'Solana', token: 'USDC + USDT + BUSD', strat_risk: '低', system_risk: '中', apr: '18%'},
    // { name: '(Solarbeam) stablecoin farming', proportion: '4.18%', platform: 'Moonriver', token: 'FRAX + USDT + USDC + BUSD', strat_risk: '低', system_risk: '中', apr: '17%'},
    // { name: '(Quickswap) stablecoin farming', proportion: '13.62%', platform: 'Polygon', token: 'USDC + TUSD', strat_risk: '低', system_risk: '低', apr: '21%'},
    { name: 'Hedge USD Stability Pool', proportion: '3.9%', platform: 'Solana', token: 'USH'},
    { name: 'Mercurial Finance', proportion: '5.8%', platform: 'Folana', token: 'USDC + USDT'},
    { name: 'Polygon PoS Staking', proportion: '16.2%', platform: 'Polygon', token: 'USDC'},
    { name: 'Solend Lending', proportion: '32.5%', platform: 'Solana', token: 'USDT'},
    { name: 'Solend Lending', proportion: '19.8%', platform: 'Solana', token: 'USDT'},
    { name: 'Stargate Farming', proportion: '1.0%', platform: 'Solana', token: 'USDT'},
    { name: 'Open Leverage Farming', proportion: '10.4%', platform: 'Solana', token: 'USDT'},
    { name: 'Sun.io USDD Farming', proportion: '10.4%', platform: 'Tron', token: 'USDD + USDT'},
    
  ];

  public strategies_usdd = [
    { name: 'Sun.io USDD Farming', proportion: '100%', platform: 'Tron', token: 'USDD + USDT'},
  ]

  async mounted () {
    await dispatchGetConstants(this.$store);
  }

   // update version
  checkVersion () {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
